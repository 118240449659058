<template>
  <div class="record-info">
    <v-runtime-template v-if="panel && element && schema" :template="panel.recordTemplate" />
    <button v-if="!shouldHidePrintButton()" class="button print-button no-print is-primary is-rounded is-large" @click="print">
      <i class="mdi mdi-printer" /> {{ i18n.print }}
    </button>
    <portal to="editModal">
      <EditModal
        v-if="editedElement"
        ref="editedElementEditModal"
        :edited-model="editedElement"
        :show-delete-button="false"
        title="edit_element"
        :config="panel"
        fetch-object-on-backend
        @onDelete="deleteElement"
        @save="editElement($event)"
        @close="hideEditModal"
      />
    </portal>
  </div>
</template>
<script>
import VRuntimeTemplate from 'v-runtime-template';

import { codemirror } from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/keymap/sublime';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/theme/idea.css';
import Api from '@/core/Api';
import i18n from 'i18n/pages/panel/_id/_documentId.json';
import redirect from '@/core/utils/redirect';

import { mapState } from 'vuex';
import JSON5 from 'json5';

export default {
  name: 'PanelDocumentIdPage',
  components: {
    EditModal: () => import(/* webpackChunkName: "EditModal" */ '@/components/modals/EditModal'),
    VRuntimeTemplate,
    codemirror,
  },

  data() {
    const cmOptions = {
      tabSize: 4,
      mode: { name: 'javascript', json: true },
      theme: 'idea',
      lineNumbers: true,
      line: true,
      autofocus: true,
      keyMap: 'sublime',
      autoCloseBrackets: true,
      extraKeys: {
        'Ctrl-Enter': () => {
          this.save();
        },
        'Ctrl-Alt-1': () => {
          this.editMode = 'code';
        },
        'Ctrl-Alt-2': () => {
          this.editMode = 'table';
        },
      },
    };
    this.loadConfig().then(() => {
      this.queryObject();
    });
    return {
      i18n,
      panel: undefined,
      JSON5,
      cmOptions,
      element: '',
      record: undefined,
      schema: undefined,
      elementAsString: '',
    };
  },
  computed: {
    ...mapState({
      currentWorkgroup: (state) => state.login.currentWorkgroup,
    }),
    recordTemplate() {
      if (this.panel && this.panel.collection) {
        const schema = this.getSchemaByName(this.panel.collection);
        if (schema) {
          return schema.recordTemplate;
        }
      }
      return undefined;
    },
    editedElement() {
      if (this.$route.query.editId) {
        return { _id: this.$route.query.editId };
      }
      return undefined;
    },
  },
  watch: {
    element: {
      handler(v) {
        this.elementAsString = JSON5.stringify(v, null, 2);
      },
      deep: true,
    },
    '$route.params.collection': {
      handler(v) {
        this.getSchema(v);
      },
      immediate: true,
    },
    '$store.state.abstractElements.objects.schemas.objects': {
      handler() {
        if (this.panel) {
          this.getSchema(this.panel.collection);
        }
      },
      deep: true,
      immediate: true,
    },
    panel: {
      handler() {
        if (this.panel) {
          this.getSchema(this.panel.collection);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    queryObject() {
      const { collection } = this.panel;
      this.$store.dispatch('abstractElements/queryObject', { collection, id: this.$route.params.documentId }).then((payload) => {
        this.loading = false;
        if (payload.success && payload.document) {
          this.element = payload.document;
          this.record = payload.document;
        } else {
          console.error(payload);
        }
      }).catch((e) => {
        this.loading = false;
      });
    },
    getSchema(name) {
      this.schema = this.getSchemaByName(name);
      if (this.schema) {
        this.recordTemplate = this.schema.recordTemplate;
      }
    },
    async loadConfig() {
      try {
        const payload = await Api.get(`/panels/${this.$route.params.id}`);
        if (payload.data && payload.data.success) {
          this.panel = payload.data.documents[0];
          console.log('_docId panel', this.panel);
        } else {
        }
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    shouldHidePrintButton() {
      let schema;
      if (this.$route.params.collection) {
        schema = this.getSchemaByName(this.$route.params.collection);
      }
      return (schema && schema.hidePrintButtonInRecordPage);
    },

    print() {
      window.print();
    },
    getSchemaByName(schemaName) {
      const schemas = this.$store.state.abstractElements.objects.schemas.objects;
      for (let i = 0; i < schemas.length; i++) {
        if (schemas[i].name === schemaName) {
          return schemas[i];
        }
      }
      return undefined;
    },
    editElement($event) {
      const storeModule = this.panel.storeModule || 'abstractElements';
      this.$store.dispatch(`${storeModule}/saveObject`, {
        collection: this.panel.collection,
        destinationBucket: this.panel.bucket,
        object: $event,
        sort: this.panel.sort,
      }).then((res) => {
        this.$store.commit('panels/resolveEditionPromise', {
          object: res.payload.data.document,
        });

        this.hideEditModal();
      });
    },
    hideEditModal() {
      if (this.$route.query.editId) {
        redirect(this.$router, [{ query: { ...this.$route.query, editId: undefined } }]);
      }
    },
    async deleteElement($event) {
      const storeModule = this.panel.storeModule || 'abstractElements';
      await this.$store.dispatch(`${storeModule}/deleteObject`, {
        collection: this.panel.collection,
        bucket: this.panel.bucket,
        object: $event,
      });
      this.hideEditModal();
    },
  },
};
</script>
<style>
.record-info .CodeMirror {
  height: auto !important;
}
</style>
<style scoped>
.print-button {
  position: fixed;
  bottom: 20px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  right: 30px;
}
</style>
