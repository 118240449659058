<template>
  <span class="prevent-click">
    <span v-if="files">
      <b-tooltip v-for="f in files" :key="f._id" :label="f.originalname">
        <a @click="download($event, f)">
          <i class="mdi mdi-file" />
        </a>
      </b-tooltip>
    </span>
  </span>
</template>
<script>
import axios from 'axios';

export default {
  props: {
    files: {
      type: Array,
      default: undefined,
    },
  },
  methods: {
    download(e, file) {
      e.preventDefault();
      e.stopPropagation();
      const baseUrl = process.env.VUE_APP_BASE_URL || 'http://localhost:3332';
      axios({
        url: `${baseUrl}/system_files/${file._id}/file`,
        method: 'GET',
        responseType: 'arraybuffer',
        headers: {
          Authorization: `Token ${this.$store.state.login.user.token}`,
        },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.originalname);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    },
  },
};
</script>
