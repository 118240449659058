<template>
  <div class="calendar-block">
    <PanelTitleBar
      :panel="config"
      :panel-params="panelParams"
      :table-view-mode="$parent.tableViewMode"
      @setTableViewMode="$parent.tableViewMode = $event"
    >
      <v-runtime-template v-if="config.header" :template="`<span>${config.header}</span>`" />
    </PanelTitleBar>
    <FullCalendar :options="calendarOptions" />
  </div>
</template>
<script>
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import '@fullcalendar/common/main.css';
import '@fullcalendar/daygrid/main.css';
import stc from 'string-to-color';

import AbstractElementList from '@/components/AbstractElementList';
import VRuntimeTemplate from 'v-runtime-template';
import PanelTitleBar from '@/components/PanelTitleBar';

export default {
  components: {
    PanelTitleBar,
    FullCalendar,
    VRuntimeTemplate,
  },

  extends: AbstractElementList,
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        eventClick: this.handleEventClick,
        events: this.events,
        headerToolbar: {
          start: 'title',
          center: 'dayGridMonth,dayGridWeek,dayGridDay',
          end: 'today prev,next',
        },

      },
    };
  },
  watch: {
    'allElements.filteredObjects': {
      handler() {
        const res = [];
        if (!this.allElements || !this.allElements.filteredObjects) {
          return [];
        }

        const titleKey = this.config.component.titleKey || 'title';
        const dateKey = this.config.component.dateKey || 'date';
        const startDateKey = this.config.component.startDateKey || 'start';
        const endDateKey = this.config.component.endDateKey || 'end';
        const { colorKey } = this.config.component;

        for (let i = 0; i < this.allElements.filteredObjects.length; i++) {
          const currentElement = this.allElements.filteredObjects[i];
          const newEvent = {
            id: currentElement._id,
            title: currentElement[titleKey],
            date: currentElement[dateKey],
            start: currentElement[startDateKey],
            end: currentElement[endDateKey],
          };
          if (colorKey) {
            newEvent.color = stc(currentElement[colorKey]);
          }
          res.push(newEvent);
        }
        this.calendarOptions.events = res;
      },
      immediate: true,
    },
  },
  methods: {
    handleEventClick(eventInfo) {
      this.$emit('onEdit', { element: { _id: eventInfo.event.id }, panel: this.config });
    },
  },
};
</script>
<style scoped>
.calendar-block >>> .fc .fc-scrollgrid {
  border-right-width: 1px !important;
  border-bottom-width: 1px !important;
}
.calendar-block >>> .fc-daygrid-day-frame {
  border: 1px solid whitesmoke;
}
</style>
