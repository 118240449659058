<template>
  <section :class="`element-list-panel ${isMobile ? 'mobile' : 'desktop'}`" style="position: relative;">
    <PanelTitleBar
      :panel="config"
      :panel-params="panelParams"
      :table-view-mode="$parent.tableViewMode"
      @setTableViewMode="$parent.tableViewMode = $event"
    >
      <v-runtime-template v-if="config.header" :template="`<span>${config.header}</span>`" />
    </PanelTitleBar>
    <slot name="description" />
    <table>
      <tr v-if="allElements && allElements.filteredObjects" class="kanban-headers">
        <td v-for="(allElements, c) in elementsByCategory" :key="c" class="kanban-column-header">
          <h6 class="title is-6">
            {{ c }}
          </h6>
        </td>
      </tr>
      <tr v-if="allElements && allElements.filteredObjects">
        <td
          v-for="(allElements, c) in elementsByCategory"
          :key="c"
          class="kanban-column"
        >
          <draggable
            :handle="isMobile ? '.drag-handle' : undefined"
            :list="allElements"
            :group="config.collection"
            class="kanban-column"
            @change="update(c, $event)"
          >
            <ListElement
              v-for="e in allElements"
              :key="e.url"
              :element="e"
              :config="config"
              class="draggable-item"
              @edit="$emit('onEdit', { element: e, panel: config })"
              @see="goToRecordPage"
            >
              <span v-if="isMobile" class="button drag-handle">
                <i class="mdi mdi-drag-variant" />
              </span>
            </ListElement>
          </draggable>
        </td>
      </tr>
    </table>
  </section>
</template>
<script>
import draggable from 'vuedraggable';
import { isMobile } from 'mobile-device-detect';
import AbstractElementList from '@/components/AbstractElementList';
import VRuntimeTemplate from 'v-runtime-template';
import PanelTitleBar from '@/components/PanelTitleBar';

import ListElement from '@/components/ListElement';

export default {
  components: {
    VRuntimeTemplate,
    PanelTitleBar,
    draggable,
    ListElement,
  },
  extends: AbstractElementList,
  data() {
    return {
      isMobile,
    };
  },
  computed: {
    elementsByCategory() {
      const res = {};
      const { categories } = this.config.component;
      for (const i in categories) {
        if (typeof categories[i] === 'string') {
          res[categories[i]] = [];
        }
      }

      const { categoryField } = this.config.component;
      if (categoryField) {
        for (let i = 0; i < this.allElements.filteredObjects.length; i++) {
          if (res[this.allElements.filteredObjects[i][categoryField]] === undefined) {
            res[this.allElements.filteredObjects[i][categoryField]] = [];
          }
          res[this.allElements.filteredObjects[i][categoryField]].push(this.allElements.filteredObjects[i]);
        }
      }
      return res;
    },
  },
  methods: {
    update(category, change) {
      if (change.added) {
        const newElement = { ...change.added.element };
        newElement[this.config.component.categoryField] = category;
        this.$store.dispatch(`${this.storeModule}/saveObject`, {
          collection: this.config.collection,
          object: newElement,
        });
      }
    },
  },
};
</script>
<style scoped>
.draggable-item {
  cursor: move;
}
.columns {
  clear: both;
  /*display: flex;*/
}
.drag-handle {
  position: absolute;
  top: 0;
  right: 0;
}
.element-list-panel.mobile {
  padding-top: 62px;
}
.column > h6, .column > .draggable-item {
  min-width: 150px;
}
table {
  table-layout: fixed;
}
.kanban-headers {
  text-align: center;
}
.kanban-column, .kanban-column-header {
  display: table-cell;
  width: 300px;
}
</style>
