<template>
  <div class="gantt-block">
    <PanelTitleBar
      :panel="config"
      :panel-params="panelParams"
      :table-view-mode="$parent.tableViewMode"
      @setTableViewMode="$parent.tableViewMode = $event"
    >
      <v-runtime-template v-if="config.header" :template="`<span>${config.header}</span>`" />
    </PanelTitleBar>
    <div class="field has-addons" style="margin-top: 10px; float: right">
      <p class="control">
        <a class="button is-small" @click="viewMode = 'Quarter Day'">
          Quarter Day
        </a>
      </p>
      <p class="control">
        <a class="button is-small" @click="viewMode = 'Half Day'">
          Half Day
        </a>
      </p>
      <p class="control">
        <a class="button is-small" @click="viewMode = 'Day'">
          Day
        </a>
      </p>
      <p class="control">
        <a class="button is-small" @click="viewMode = 'Week'">
          Week
        </a>
      </p>
      <p class="control">
        <a class="button is-small" @click="viewMode = 'Month'">
          Month
        </a>
      </p>
    </div>
    <div style="clear: both" />
    <div style="display: flex;">
      <table class="sidebar">
        <tr>
          <th/>
          <th v-for="f in config.component.sidebarFields" :key="f">
            {{f}}
          </th>
        </tr>
        <tr v-if="allElements.filteredObjects && config.component.sidebarFields" v-for="o in allElements.filteredObjects" :key="o._id" class="sidebar-item">
          <td>
            <a @click="editElement(o)">
              <i class="mdi mdi-pencil" />
            </a>
          </td>
          <td v-for="f in config.component.sidebarFields" :key="f">
            <div>
              {{getProp(o, f)}}
            </div>
          </td>
        </tr>
      </table>
      <svg ref="gantt" />
    </div>
  </div>
</template>
<script>
import AbstractElementList from '@/components/AbstractElementList';
import getProp from '@/core/utils/getProp';
import VRuntimeTemplate from 'v-runtime-template';
import PanelTitleBar from '@/components/PanelTitleBar';

export default {
  extends: AbstractElementList,
  components: {
    VRuntimeTemplate,
    PanelTitleBar,
  },
  data() {
    return {
      getProp,
      gantt: {},
      tasks:[],
      viewMode: 'Day',
      ganttOptions: {
        view_modes: ['Quarter Day', 'Half Day', 'Day', 'Week', 'Month'],
        on_click: task => {
          this.$emit('onEdit', { element: { _id: task.id }, panel: this.config });
        },
        on_date_change: (task, start, end) => {
          this.$emit('task-date-updated', { task, start, end });
        },
        on_progress_change: (task, progress) => {
          this.$emit('task-progress-updated', { task, progress });
        },
        //I doubt you will ever need this as the developer already knows what view mode they set.
        on_view_change: (mode) => {
          this.$emit('view-mode-updated', mode);
        }
      }
    };
  },
  watch: {
    viewMode: 'updateViewMode',
    'allElements.filteredObjects': 'updateTasks',
  },
  methods: {
    updateViewMode () {
      console.log('change_view_mode', this.viewMode[0].toUpperCase() + this.viewMode.substring(1));
      this.gantt.change_view_mode(this.viewMode[0].toUpperCase() + this.viewMode.substring(1));
    },
    async updateTasks () {
      const res = [];
      if (!this.allElements || !this.allElements.filteredObjects) {
        return [];
      }

      const titleKey = this.config.component.titleKey || 'title';
      const startDateKey = this.config.component.startDateKey || 'start';
      const endDateKey = this.config.component.endDateKey || 'end';
      const previousTaskKey = this.config.component.previousTaskKey || 'previousTask';
      const completionKey = this.config.component.completionKey || 'completionTask';

      if (this.allElements) {
        for (let i = 0; i < this.allElements.filteredObjects.length; i++) {
          const currentElement = this.allElements.filteredObjects[i];

          let dep = currentElement[previousTaskKey];
          if (dep && dep._id) {
            dep = dep._id;
          }

          const newEvent = {
            id: currentElement._id,
            name: currentElement[titleKey],
            start: currentElement[startDateKey],
            end: currentElement[endDateKey],
            progress: currentElement[completionKey],
          };
          if (dep) {
            newEvent.dependencies = dep;
          }
          res.push(newEvent);
        }
      }
      if (this.gantt.refresh) {
        this.gantt.refresh(res);
      } else {
        await this.$loadScript('https://cdnjs.cloudflare.com/ajax/libs/frappe-gantt/0.5.0/frappe-gantt.min.js');

        this.gantt = new Gantt(this.$refs.gantt, res, this.ganttOptions);
      }
    },
    editElement (element) {
      this.$emit('onEdit', { element, panel: this.config });
    },
  }
};
</script>
<style scoped>
.gantt-block {
  padding: 0 !important;
}
.gantt-block >>> .title {
  color: white;
}
.sidebar {
  background: #f2f2f2;
  width: unset;
}
.sidebar th {
  height: 60px;
  border-bottom: 2px solid #e0e0e0;
}
.sidebar-item > td {
  height: 38px;
  padding: 0px;
  border: 1px solid #e8e8e8;
  overflow: hidden;
}
.sidebar-item > td > div, .sidebar-item > td > a {
  height: 100%;
  overflow: hidden;
  height: 36px;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
  padding: 6px;
  padding-left: 10px;
  padding-right: 10px;
  display: block;
}
</style>
<style>
.gantt .grid-background {
  fill: none; }

.gantt .grid-header {
  fill: #ffffff;
  stroke: #e0e0e0;
  stroke-width: 1.4; }

.gantt .grid-row {
  fill: #ffffff; }

.gantt .grid-row:nth-child(even) {
  fill: #f5f5f5; }

.gantt .row-line {
  stroke: #ebeff2; }

.gantt .tick {
  stroke: #e0e0e0;
  stroke-width: 0.2; }
  .gantt .tick.thick {
    stroke-width: 0.4; }

.gantt .today-highlight {
  fill: #fcf8e3;
  opacity: 0.5; }

.gantt .arrow {
  fill: none;
  stroke: #666;
  stroke-width: 1.4; }

.gantt .bar {
  fill: #b8c2cc;
  stroke: #8D99A6;
  stroke-width: 0;
  transition: stroke-width .3s ease;
  user-select: none; }

.gantt .bar-progress {
  fill: #a3a3ff; }

.gantt .bar-invalid {
  fill: transparent;
  stroke: #8D99A6;
  stroke-width: 1;
  stroke-dasharray: 5; }
  .gantt .bar-invalid ~ .bar-label {
    fill: #555; }

.gantt .bar-label {
  fill: #fff;
  dominant-baseline: central;
  text-anchor: middle;
  font-size: 12px;
  font-weight: lighter; }
  .gantt .bar-label.big {
    fill: #555;
    text-anchor: start; }

.gantt .handle {
  fill: #ddd;
  cursor: ew-resize;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s ease; }

.gantt .bar-wrapper {
  cursor: pointer;
  outline: none; }
  .gantt .bar-wrapper:hover .bar {
    fill: #a9b5c1; }
  .gantt .bar-wrapper:hover .bar-progress {
    fill: #8a8aff; }
  .gantt .bar-wrapper:hover .handle {
    visibility: visible;
    opacity: 1; }
  .gantt .bar-wrapper.active .bar {
    fill: #a9b5c1; }
  .gantt .bar-wrapper.active .bar-progress {
    fill: #8a8aff; }

.gantt .lower-text, .gantt .upper-text {
  font-size: 12px;
  text-anchor: middle; }

.gantt .upper-text {
  fill: #555; }

.gantt .lower-text {
  fill: #333; }

.gantt .hide {
  display: none; }

.gantt-container {
  position: relative;
  font-size: 12px; }
  .gantt-container .popup-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    padding: 0;
    color: #959da5;
    border-radius: 3px; }
    .gantt-container .popup-wrapper .title {
      border-bottom: 3px solid #a3a3ff;
      padding: 10px; }
    .gantt-container .popup-wrapper .subtitle {
      padding: 10px;
      color: #dfe2e5; }
    .gantt-container .popup-wrapper .pointer {
      position: absolute;
      height: 5px;
      margin: 0 0 0 -5px;
      border: 5px solid transparent;
      border-top-color: rgba(0, 0, 0, 0.8); }</style>
