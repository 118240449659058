<template>
  <section :class="`element-list-panel ${isMobile ? 'mobile' : 'desktop'}`" style="position: relative;">
    <PanelTitleBar
      :panel="config"
      :panel-params="panelParams"
      :table-view-mode="$parent.tableViewMode"
      @setTableViewMode="$parent.tableViewMode = $event"
    >
      <v-runtime-template v-if="config.header" :template="`<span>${config.header}</span>`" />
    </PanelTitleBar>
    <slot name="description" />
    <b-message v-if="allElements && allElements.isLoading === false && allElements.filteredObjects.length === 0">
      Aucun élément à afficher.
    </b-message>
    <table v-if="allElements && allElements.filteredObjects && allElements.filteredObjects.length && lineDefiningElements && lineDefiningElements.length">
      <tr>
        <th>Objectifs</th>
        <th v-for="c in config.component.categories.filter(c => c !== 'Objectif')">
          {{c}}
        </th>
      </tr>
      <tr
        v-for="(lineElement, c) in lineDefiningElements"
        :key="c"
        style="border-top: 2px solid #ccc;"
      >
        <td class="kanban-column" style="border-right: 2px solid #ccc;">
          <ListElement
            :element="lineElement"
            :config="config"
            @edit="$emit('onEdit', { element: lineElement, panel: config })"
            @see="goToRecordPage"
          >
            <span v-if="isMobile" class="button drag-handle">
              <i class="mdi mdi-drag-variant" />
            </span>
          </ListElement>
        </td>
        <td v-for="c in config.component.categories.filter(c => c !== 'Objectif')">
          <draggable
            :handle="isMobile ? '.drag-handle' : undefined"
            :list="allElements.filteredObjects.filter(o => o.parent && o.parent._id === lineElement._id && o.type === c)"
            :group="config.collection"
            @change="update(c, lineElement, $event)"
          >
            <ListElement
              v-for="e in allElements.filteredObjects.filter(o => o.parent && o.parent._id === lineElement._id && o.type === c)"
              :key="e._id"
              :element="e"
              :config="config"
              class="draggable-item"
              @edit="$emit('onEdit', { element: e, panel: config })"
              @see="goToRecordPage"
            >
              <span v-if="isMobile" class="button drag-handle">
                <i class="mdi mdi-drag-variant" />
              </span>
            </ListElement>
          </draggable>
          <a class="add-button" @click="addElement({[config.component.categoryField]: c, parent: lineElement})">
            Ajouter un élément...
          </a>
        </td>
      </tr>
      <tr class="new-line-tr">
        <td>
          <a class="add-button" @click="addElement({[config.component.categoryField]: 'Objectif'})">
            Ajouter un élément...
          </a>
        </td>
        <td v-for="c in config.component.categories.filter(c => c !== 'Objectif')">
        </td>
      </tr>
      <tr class="unsorted-elements-line" v-if="allElements.filteredObjects.filter(o => !o.parent && o.type !== 'Objectif').length">
        <td>Non classé</td>
        <td v-for="c in config.component.categories.filter(c => c !== 'Objectif')" :key="c">
          <draggable
            :handle="isMobile ? '.drag-handle' : undefined"
            :list="allElements.filteredObjects.filter(o => !o.parent && o.type !== 'Objectif' && o.type === c)"
            :group="config.collection"
            @change="update(c, undefined, $event)"
          >
            <ListElement
              v-for="e in allElements.filteredObjects.filter(o => !o.parent && o.type !== 'Objectif' && o.type === c)"
              :key="e._id"
              :element="e"
              :config="config"
              @edit="$emit('onEdit', { element: e, panel: config })"
              @see="goToRecordPage"
            >
              <span v-if="isMobile" class="button drag-handle">
                <i class="mdi mdi-drag-variant" />
              </span>
            </ListElement>
          </draggable>
        </td>
      </tr>
    </table>
  </section>
</template>
<script>
import draggable from 'vuedraggable';
import { isMobile } from 'mobile-device-detect';
import AbstractElementList from '@/components/AbstractElementList';
import PanelTitleBar from '@/components/PanelTitleBar';
import VRuntimeTemplate from 'v-runtime-template';

import ListElement from '@/components/ListElement';

export default {
  components: {
    PanelTitleBar,
    VRuntimeTemplate,
    draggable,
    ListElement,
  },
  extends: AbstractElementList,
  data() {
    return {
      isMobile,
    };
  },
  computed: {
    lineDefiningElements () {
      if(this.allElements && this.allElements.filteredObjects) {
        return this.allElements.filteredObjects.filter(o => o.type === 'Objectif');
      } else return [];
    },
    elementsByCategory () {
      const res = {};
      const { lines } = this.config.component;
      for (const i in lines) {
        if (typeof lines[i] === 'string') {
          res[lines[i]] = [];
        }
      }

      const { lineField } = this.config.component;
      if (lineField) {
        for (let i = 0; i < this.allElements.filteredObjects.length; i++) {
          if (res[this.allElements.filteredObjects[i][lineField]] === undefined) {
            res[this.allElements.filteredObjects[i][lineField]] = [];
          }
          res[this.allElements.filteredObjects[i][lineField]].push(this.allElements.filteredObjects[i]);
        }
      }
      return res;
    },
  },
  methods: {
    getSchemaByName(schemaName) {
      const schemas = this.$store.state.abstractElements.objects.schemas.objects;
      for (let i = 0; i < schemas.length; i++) {
        if (schemas[i].name === schemaName) {
          return schemas[i];
        }
      }
      return undefined;
    },
    addElement(defaultSpecifiedValue) {
      const schema = this.getSchemaByName(this.config.schema);
      let defaultValue = defaultSpecifiedValue || {};
      if (this.$store.state.login.currentWorkgroup) {
        defaultValue.workgroup = this.$store.state.login.currentWorkgroup;
      }
      if (schema && schema.defaultValue) {
        defaultValue = {
          ...defaultValue,
          ...schema.defaultValue,
        };
      }
      if (this.config.defaultValue) {
        defaultValue = {
          ...defaultValue,
          ...this.config.defaultValue,
        };
      }
      this.$store.commit('panels/setAddedElement', { ...defaultValue });
      this.$store.commit('panels/setAddedElementPanel', this.config);
    },
    update(category, lineElement, change) {
      if (change.added) {
        const newElement = { ...change.added.element };
        newElement[this.config.component.categoryField] = category;
        newElement.parent = lineElement;
        console.log('update', category, newElement, category, this.config.bucket);
        this.$store.dispatch(`${this.storeModule}/saveObject`, {
          collection: this.config.collection,
          destinationBucket: this.config.bucket,
          object: newElement,
        });
      }
    },
    filterElementsByColumn (elements) {
      const res = {};
      const { columns } = this.config.component;
      for (const i in columns) {
        if (typeof columns[i] === 'string') {
          res[columns[i]] = [];
        }
      }

      const { columnField } = this.config.component;
      if (columnField) {
        for (let i = 0; i < elements.length; i++) {
          if (res[elements[i][columnField]] === undefined) {
            res[elements[i][columnField]] = [];
          }
          res[elements[i][columnField]].push(elements[i]);
        }
      }
      return res;
    },
    filterCellElements(lineId, category) {
      return this.allElements.filteredObjects.filter(o => o.parent && o.parent._id === lineId);
    }
  },
};
</script>
<style scoped>
.draggable-item {
  cursor: move;
}
.columns {
  clear: both;
  /*display: flex;*/
}
.drag-handle {
  position: absolute;
  top: 0;
  right: 0;
}
.element-list-panel.mobile {
  padding-top: 62px;
}
.column > h6, .column > .draggable-item {
  min-width: 150px;
}
table {
  table-layout: fixed;
}
.kanban-headers {
  text-align: center;
}
.kanban-column, .kanban-column-header {
  display: table-cell;
  width: 300px;
}
.element-list-panel >>> th {
  text-align: center !important;
}
.add-button {
  display: block;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 20px;
  color:  #aaa;
  border: 1px dashed #dedede;
}
.add-button:hover {
  background: whitesmoke;
}
.element-list-panel >>> .unsorted-elements-line {
  border-top: 4px solid #ccc;
}
.element-list-panel >>> .unsorted-elements-line td, .element-list-panel >>> .new-line-tr td {
  border: none;
}
</style>
