<template>
  <span class="prevent-click">
    <Modal :active="modalActive" class="modal" @close="modalActive = false">
      <table class="table is-striped is-hoverable is-fullwidth results">
        <thead>
          <tr>
            <th style="width: 40px" />
            <th v-for="f in relationSchema.items.onlyFields" :key="f">
              {{ f }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="d in relationData" :key="d._id">
            <td>
              <i v-if="icon" :class="`mdi mdi-${icon}`" />
            </td>
            <td v-for="f in relationSchema.items.onlyFields" :key="f">
              {{ d[f] }}
            </td>
          </tr>
        </tbody>
      </table>
    </Modal>
    <span @click="modalActive = true">
      <span v-if="relationData && relationData.length" class="tag" style="cursor: pointer">
        <i v-if="icon" :class="`mdi mdi-${icon}`" />
        {{ relationData.length }}
      </span>
    </span>
  </span>
</template>
<script>
import Modal from '@/components/modals/Modal';

export default {
  name: 'RelationDetails',
  components: {
    Modal,
  },
  props: {
    relation: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: undefined,
    },
    refetchData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    function getPropertySchema(schema, relation) {
      const propertyLookup = schema.fields.filter((f) => f.model === relation);

      if (propertyLookup && propertyLookup.length) {
        return propertyLookup[0];
      }
    }
    return {
      modalActive: false,
      relationData: this.$parent.element[this.relation],
      relationSchema: getPropertySchema(this.$parent.schema, this.relation),
    };
  },
};
</script>
