<template>
  <div :class="`${isMobile ? 'mobile': 'desktop'}` ">
    <div v-if="panel && panel.backgroundUrl" :style="`background: url(${panel.backgroundUrl}); background-position: 0% ${panel.backgroundPos || '33'}%`" class="panel-background" />
    <b-message v-if="panel && !panel.collection" type="is-danger" has-icon style="max-width: 600px; margin: auto; margin-top: 80px; margin-bottom: 40px">
      {{ i18n.error_no_collection }}
    </b-message>
    <portal-target name="data-filter-menu" />
    <div v-if="isLoading" style="margin: 80px">
      <b-loading :is-full-page="true" :active="true" :can-cancel="false" />
    </div>
    <div v-else-if="panel" :class="`element-list-panel ${panel.fullWidth || tableViewMode ? 'full-width': 'centered'}`">
      <component
        :is="panelComponent"
        :style="panel.style + '; width: 100%;'"
        :template="panel.template"
        :config="panel"
        :sort="sort"
        :panel-params="panelParams"
        class="element-panel"
        @onEdit="onEdit"
      >
        <template v-slot:description="{elements}">
          <v-runtime-template v-if="panel.description" :template="`<span>${panel.description}</span>`" />
        </template>
        <template v-slot:footer="{elements}">
          <v-runtime-template v-if="panel.footer" :template="`<span>${panel.footer}</span>`" />
        </template>
      </component>
    </div>
    <div v-else>
      <b-message has-icon type="is-danger">
        <h2 class="title is-2" style="color: #86011b">
          {{ i18n.error }}
        </h2>
        {{ i18n.no_panel_found }}
      </b-message>
    </div>
    <portal to="editModal">
      <EditModal
        v-if="!isLoading && panel"
        ref="editedElementEditModal"
        :edited-model="editedElement"
        :show-delete-button="true"
        :title="i18n.edit_element"
        :config="panel"
        fetch-object-on-backend
        @onDelete="deleteElement"
        @save="editElement($event)"
        @close="hideEditModal"
      />
    </portal>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { isMobile } from 'mobile-device-detect';

import deepClone from '@/core/utils/deepClone';
import VRuntimeTemplate from 'v-runtime-template';

import Card from '@/components/Card';
import ListBlock from '@/components/blocks/List';
import ArrayBlock from '@/components/blocks/Array';
import KanbanBlock from '@/components/blocks/Kanban';
import _2dKanbanBlock from '@/components/blocks/2DKanban';
import CalendarBlock from '@/components/blocks/Calendar';
import GanttBlock from '@/components/blocks/Gantt';

import { computeInheritedProperties } from '@/core/inheritedProperties';
import Api from '@/core/Api';
import i18n from 'i18n/pages/panel/_id/index.json';
import PermissionManager from '@/permissions/PermissionManager';
import actions from '@/permissions/actions';
import BlockEditor from '@/components/BlockEditor';
import { ToastProgrammatic as Toast } from 'buefy';
import CollaboratingUsersPreview from '@/components/CollaboratingUsersPreview';
import redirect from '@/core/utils/redirect';

import publishErrorReport from '@/core/utils/publishErrorReport';

export default {
  name: 'PanelIdPage',

  components: {
    EditModal: () => import(/* webpackChunkName: "EditModal" */ '@/components/modals/EditModal'),
    VRuntimeTemplate,
    Card,
    list: ListBlock,
    kanban: KanbanBlock,
    'twowayskanban': _2dKanbanBlock,
    array: ArrayBlock,
    gantt: GanttBlock,
    calendar: CalendarBlock,
    BlockEditor,
    CollaboratingUsersPreview,
  },
  data() {
    return {
      PermissionManager,
      actions,
      i18n,
      isMobile,
      errorStatus: undefined,
      panelParams: {},
      isLoading: false,
      availableBlocks: ['list', 'kanban', 'twowayskanban', 'calendar', 'array', 'gantt'],
    };
  },
  computed: {
    ...mapState({
      currentWorkgroup: (state) => state.login.currentWorkgroup,
      config: (state) => state.config.config,
      editingView: (state) => state.panels.editingView,
      panels: (state) => state.abstractElements.objects.panels,
      sort: (state) => state.currentPanel.currentPanelSort,
      loadedPanel: (state) => state.currentPanel.currentPanel,
      editLocked: (state) => state.currentPanel.editLocked,
    }),
    backendPagination: {
      get() {
        return this.$store.state.experimentalFeatures.experimentalFeatures.backendPagination
      },
    },
    storeModule() {
      return this.backendPagination ? 'abstractElementsPaginated' : this.panel.storeModule || 'abstractElements';
    },
    panelCopy: {
      get() {
        return this.$store.state.panels.editedPanel;
      },
      set(v) {
        this.$store.commit('panels/setEditedPanel', v);
      },
    },
    tableViewMode: {
      get () {
        if (this.$route.query.tableViewMode && this.$route.query.tableViewMode === 'true') {
          return true;
        } else {
          return false;
        }
      },
      set (v) {
        if (v) {
          redirect(this.$router, [{ query: { ...this.$route.query, tableViewMode: 'true' } }]);
        } else {
          const newQueryParams = deepClone(this.$route.query);
          delete newQueryParams.tableViewMode;
          redirect(this.$router, [{ query: newQueryParams }]);
        }
      }
    },
    editedElement () {
      if (this.$route.query.editId) {
        return { _id: this.$route.query.editId };
      }
      return undefined;
    },
    panelComponent() {
      return this.tableViewMode ? 'array' : (this.panel.component && this.availableBlocks.indexOf(this.panel.component.name) !== -1) ? this.panel.component.name : 'list';
    },
    panel() {
      return this.editingView ? this.panelCopy : this.loadedPanel;
    },
    panelSchema: {
      get() {
        const schemas = this.$store.state.abstractElements.objects.schemas.objects;
        if (schemas) {
          for (let i = 0; i < schemas.length; i++) {
            if (schemas[i].name === 'panels') {
              return schemas[i];
            }
          }
        }
        return undefined;
      },
    },
  },
  watch: {
    '$route.params.id': {
      handler: 'searchPanel',
      immediate: true,
    },
    editingView(v) {
      if (v) {
        this.panelCopy = deepClone(this.loadedPanel);
      } else {
        this.searchPanel();
      }
    },
  },
  methods: {
    editElement($event) {
      this.$refs.editedElementEditModal.saveLoading = true;
      this.$store.dispatch(`${this.storeModule}/saveObject`, {
        collection: this.panel.collection,
        destinationBucket: this.panel.bucket,
        object: $event,
        sort: this.panel.sort,
        notifyLock: `see/view/${this.panel._id}`,
      }).then((res) => {
        this.$store.commit('panels/resolveEditionPromise', {
          object: res.payload.data.document,
        });

        this.$refs.editedElementEditModal.saveLoading = false;
        this.hideEditModal();
      }).catch((e) => {
        this.$refs.editedElementEditModal.saveLoading = false;
        console.error(e);
        //publishErrorReport({ document: $event, error: e.toString(), stacktrace: e.stack });
      });
    },
    hideEditModal() {
      if (this.$route.query.editId) {
        redirect(this.$router, [{ query: { ...this.$route.query, editId: undefined } }]);
      }
    },
    async deleteElement($event) {
      this.$refs.editedElementEditModal.deleteLoading = true;
      await this.$store.dispatch(`${this.storeModule}/deleteObject`, {
        collection: this.panel.collection,
        bucket: this.panel.bucket,
        object: $event,
      });
      this.hideEditModal();
      this.$refs.editedElementEditModal.deleteLoading = false;
    },
    setPanelParam(key, value) {
      this.panelParams[key] = value;
      this.panelParams = {
        ...this.panelParams,
        [key]: value,
      };
    },
    onEdit($event) {
      redirect(this.$router, [{ query: { ...this.$route.query, editId: $event.element._id } }]);
    },
    async searchPanel() {
      this.$store.commit('panels/setEditingView', false);
      this.isLoading = true;
      try {
        const payload = await Api.get(`/panels/${this.$route.params.id}`);
        if (payload.data && payload.data.success) {
          this.$store.commit('currentPanel/setCurrentPanel', computeInheritedProperties(payload.data.documents[0], this.config));
        } else {
          this.panelCopy = undefined;
        }
        this.isLoading = false;
      } catch (e) {
        console.error(e);
        if (e.response && e.response.status) {
          this.errorStatus = e.response.status;
          this.$store.dispatch('errors/requestError', { message: 'Unauthorized', error: e, status: e.response.status });
        }
        this.isLoading = false;
      }
    },
  },
};
</script>
<style scoped>
.element-list-panel.centered >>> .container {
  max-width: 1000px;
  margin: auto;
}
.element-list-panel.full-width {
  padding-left: 20px;
}
.desktop .element-panel >>> .container {
  padding-left: 20px;
  padding-right: 20px;
}
.panel-background {
  position: absolute;
  top: -56px;
  right: 0;
  width: 100%;
  opacity: 0.5;
  height: 360px;
  background-size: cover !important;
}
</style>
