<template>
  <div>
    <section class="element-list-panel" style="position: relative;">
      <PanelTitleBar
        :panel="config"
        :panel-params="panelParams"
        :table-view-mode="$parent.tableViewMode"
        @setTableViewMode="$parent.tableViewMode = $event"
      />
      <div class="container">
        <v-runtime-template v-if="config.header" :template="`<span>${config.header}</span>`" />
        <slot name="description" />
        <slot name="search" />
        <portal to="data-filter-menu">
          <DataFilterMenu v-if="config.hasDataFilterMenu" :schema="schema" :filteredObjects="allElements ? allElements.filteredObjects: undefined"/>
        </portal>
        <b-message v-if="allElements && allElements.error" type="is-danger" has-icon>
          <b>Error</b><br/>
          <span v-if="allElements.error && allElements.error.message">
            {{allElements.error.message}}
          </span>
        </b-message>
        <div v-if="isLoading || (allElements && allElements.isLoading !== false)" class="loading">
          <div v-for="i in paginationPerPage" :key="i" class="card" style="padding: 24px; display: flex">
            <span style="width: 32px;">
              <b-skeleton circle width="24px" height="24px" />
            </span>
            <span style="width: 120px; padding-left: 20px; padding-right: 20px;">
              <b-skeleton />
            </span>
            <span style="flex-grow: 1">
              <b-skeleton />
            </span>
            <span style="width: 120px; padding-left: 20px; padding-right: 20px;">
              <b-skeleton />
            </span>
            <span style="width: 22px;">
              <b-skeleton circle width="14px" height="14px" />
            </span>
          </div>
        </div>
        <vue-context v-if="config.contextMenu && config.contextMenu.length" ref="menu" @close="onMenuClose">
          <li v-for="action in config.contextMenu" :key="action.label">
            <a href="#" @click.prevent.stop="contextMenuItemClick(action)">{{ action.label }}</a>
          </li>
        </vue-context>
        <div v-if="allElements && allElements.isLoading === false && allElements.paginatedFilteredElements" :class="`list-container ${isMobile ? 'mobile' : 'desktop'}`">
          <ListElement
            v-for="e in allElements.paginatedFilteredElements"
            :key="e._id"
            :element="e"
            :config="config"
            :schema="schema"
            @edit="$emit('onEdit', { element: e, panel: config })"
            @see="goToRecordPage"
            @toggleSelected="toggleSelected(e)"
            @contextmenu.native.prevent="openMenu($event, e)"
          >
            <template slot="mobile-menu">
              <ul v-if="isMobile && contextMenuElement === e" class="mobile-menu">
                <li v-for="action in config.contextMenu" :key="action.label">
                  <a class="menu-action" @click.prevent.stop="contextMenuItemClick(action)">
                    <i class="mdi mdi-48px mdi-gesture-tap" />
                    <span class="menu-label">
                      {{ action.label }}
                    </span>
                  </a>
                </li>
              </ul>
            </template>
          </ListElement>
          <slot :elements="elements" name="footer" />
        </div>
        <div v-else-if="allElements && !allElements.isLoading" style="text-align: center;">
          <div class="content has-text-grey has-text-centered" style="margin-top: 200px">
            <p>{{ i18n.no_records }}</p>
          </div>
        </div>
      </div>
    </section>
    <pagination-controls @onPerPageChange="onPerPageChange" @onPageChange="onPageChange" />
  </div>
</template>
<script>
import Vue from 'vue';

import { VueContext } from 'vue-context';
import { isMobile } from 'mobile-device-detect';

import HelpLinkComponent from '@/components/ui/HelpLink';
import ListElement from '@/components/ListElement';
import AbstractElementList from '@/components/AbstractElementList';
import PaginationControls from '@/components/PaginationControls';
import Pagination from '@/core/mixins/pagination.js';
import i18n from 'i18n/components/blocks/List.json';
import RelationDetailsComponent from './components/RelationDetails';
import FilesComponent from './components/Files';
import 'vue-context/dist/css/vue-context.css';
import PanelTitleBar from '@/components/PanelTitleBar';
import VRuntimeTemplate from 'v-runtime-template';

import DataFilterMenu from '@/components/DataFilterMenu.vue';

Vue.component('files', FilesComponent);
Vue.component('helplink', HelpLinkComponent);
Vue.component('relationDetails', RelationDetailsComponent);

export default {
  components: {
    VRuntimeTemplate,
    PanelTitleBar,
    ListElement,
    VueContext,
    PaginationControls,
    DataFilterMenu,
  },
  extends: AbstractElementList,
  mixins: [Pagination],
  data() {
    return {
      i18n,
      isMobile,
      contextMenuElement: undefined,
    };
  },
  methods: {
    openSelectionMenu(event) {
      this.openMenu(event, undefined, event.target.offsetTop - 160);
    },
    openMenu(event, element, y) {
      if (this.$refs.menu) {
        if (element) {
          this.selection = [element];
        }
        if (y) {
          this.$refs.menu.open({
            clientX: event.clientX,
            clientY: y,
          });
        } else {
          this.$refs.menu.open(event);
        }
      }
    },
    contextMenuItemClick(action) {
      if (this.$refs.selectionMenu) {
        this.$refs.selectionMenu.doAction(action);
      }
      if (this.$refs.menu) {
        this.$refs.menu.close();
      }
    },
    onMenuClose() {
      this.selection = [];
    },
  },
};
</script>
<style scoped>
.card-content {
    padding: 10px;
}
.list-container {
    padding-bottom: 10px;
}

.mobile-menu {
  border-top: 2px solid #ccc;
}
.mobile-menu li {
  list-style: none;
  display: inline-block;
  width: 120px;
  margin: 10px;
  background: whitesmoke;
  text-align: center;
}

.mobile-menu .menu-action .mdi {
  display: block;
}

.element-list-panel {
  padding-bottom: 44px;
}
</style>
