<template>
  <a class="help-link" @click="$store.commit('config/setActiveHelpTopicPath', path)">
    <i class="mdi mdi-help-box" /> {{ title }}
  </a>
</template>
<script>
import dotProp from 'dot-prop';
import help from '@/help/fr.json';

export default {
  props: {
    path: {
      type: String,
      required: true,
    },
  },
  data() {
    console.log(this.getActiveTopic());
    const activeTopic = this.getActiveTopic();
    console.log('help', help);
    return {
      help,
      title: activeTopic ? activeTopic.title : 'helplink',
    };
  },
  methods: {
    getActiveTopic() {
      let topicPath = this.path;
      console.log('topicPath', topicPath);
      if (!topicPath) {
        return;
      }
      topicPath = topicPath.split('.').reduce((r, a) => r.concat(a, 'topics'), ['topics']).slice(0, -1).join('.');
      console.log('topicPath', topicPath, dotProp.get(help, topicPath), help);

      return dotProp.get(help, topicPath);
    },

  },
};
</script>
<style scoped>
a {
  text-decoration-line: underline;
  text-decoration-style: dotted;
}
</style>
