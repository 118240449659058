<template>
  <div :class="{ 'card': true, selected }">
    <header v-if="title" class="card-header">
      <p class="card-header-title">
        {{ title }}
      </p>
    </header>
    <div class="card-content">
      <div class="content has-text-centered">
        <slot />
      </div>
    </div>
    <footer v-if="$slots.footer" class="card-footer">
      <div class="card-footer-item">
        <span>
          <slot name="footer" />
        </span>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: undefined,
    },
    icon: {
      type: String,
      default: undefined,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
  .card.selected .card-header {
    background: #7957d5;
  }
  .card.selected .card-header-title {
    color: white;
  }
  .card.selected .card-content {
    background: #7957d561;
  }
</style>
