export function computeInheritedProperties(document, config) {
  if (document && document._computedProperties) {
    const propNames = Object.keys(document._computedProperties);
    for (let j = 0; j < propNames.length; j++) {
      const propAlias = document._computedProperties[propNames[j]];
      let propValue;
      if (propAlias.startsWith('@config/')) {
        propValue = config[propAlias.split('/')[1]];
      }
      if (document[propNames[j]] === undefined) {
        document[propNames[j]] = propValue;
      }
    }
  }
  return document;
}
