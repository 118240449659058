<template>
  <div :class="`menu ${opened ? 'opened': ''} data-filter-menu`">
    <span class="toggle-button-wrapper">
      <b-tooltip label="Filter" position="is-right">
        <a class="toggle-button" @click="opened = !opened">
          <i class="mdi mdi-filter mdi-24px" />
        </a>
      </b-tooltip>
    </span>
    <div class="menu-content">
      <div class="menu-header">
        <b-dropdown
          v-if="schema && schema.fields"
          v-model="currentField"
          aria-role="list"
          style="width: 100%"
        >
          <template #trigger>
            <a class="button">
              <i class="mdi mdi-format-letter-case" />
              {{currentField ? currentField.label : 'Séléctionnez un champ'}}
              <i class="mdi mdi-menu-down"/>
            </a>
          </template>
          <b-dropdown-item
            v-for="(f, index) in schema.fields"
            :key="index"
            :value="f" aria-role="listitem">
            <div class="media">
              <div class="media-content">
                  <i class="mdi mdi-format-letter-case" />
                  {{f.label}}
              </div>
            </div>
          </b-dropdown-item>
        </b-dropdown>
        <a class="button" @click="clearFilter">
          <i class="mdi mdi-filter-off" />
        </a>
      </div>
      <div class="field-values menu" v-if="currentField" style="position: relative">
        <ul class="menu-list" v-if="currentField && currentField.model">
          <li v-for="(v, i) in getFieldValues(currentField.model)" :key="i">
            <a @click="filter(i)">
              {{i}} ({{v}})
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import redirect from '@/core/utils/redirect';

export default {
  props: {
    schema: {
      type: Object,
      default: undefined
    },
    filteredObjects: {
      type: Array,
      default: undefined
    }
  },
  data () {
    return {
      opened: false,
      currentField: undefined
    };
  },
  methods: {
    getFieldValues (propName) {
      const values = {};
      if (this.filteredObjects && this.currentField) {
        for (let doc of this.filteredObjects) {
          const key = JSON.stringify(doc[this.currentField.model]);
          if (values[key] === undefined) {
            values[key] = 1;
          } else {
            values[key] ++;
          }
        }
      }
      return values;
    },
    filter (propValue) {
      redirect(this.$router, [{ query: { ...this.$route.query, searchText: `{ "${this.currentField.model}": ${propValue} }` } }]);
    },
    clearFilter() {
      redirect(this.$router, [{ query: { ...this.$route.query, searchText: '' } }]);
    }
  }
};
</script>
<style scoped>
.menu-header {
  display: flex;
}
.data-filter-menu >>> .dropdown-trigger {
  flex-grow: 1;
}
.data-filter-menu >>> .dropdown-trigger > .button {
  width: 100%;
}
.menu {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 10000;
  padding: 2px;
}
.menu .menu-content {
  position: absolute;
  left: -296px;
  background: whitesmoke;
  border-right: 1px solid #ccc;
  top: 0;
  bottom: 0;
  width: 300px;
  transition: left 0.2s;
  overflow: hidden;
}
.menu.opened .menu-content {
  left: 0px;
}
.toggle-button-wrapper {
  position: absolute;
  transition: left 0.2s;
  left: 0;
  background: whitesmoke;
  border: 1px solid #ccc;
  border-left: 0;
  border-radius: 0 6px 6px 0;
  margin-top: 20px;
  display: inline-block;
}
.menu.opened .toggle-button-wrapper {
  left: 300px;
}

.toggle-button-wrapper a {
  padding-left: 6px;
  padding-right: 6px;
  color: black;
}
ul {
  list-style: none;
  margin: 0;
}
</style>
